export default class AdvertisementsPostModel {
    constructor(item) {
        this._setName(item);
        this._setUrl(item);
        this._setAdType(item);
        this._setActive(item);
        this._setPhoto(item);
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }

    /**
     * set url
     * @param url
     * @private
     */
    _setUrl({url}) {
        this.url = url;
    }

    /**
     * set active
     * @param active
     * @private
     */
    _setActive({active}) {
        this.active = active;
    }

    /**
     * set adType
     * @param adType
     * @private
     */
    _setAdType({adType}) {
        if (adType?.id)
            this.adTypeId = adType.id;
        else this.adTypeId = adType;
    }

    /**
     * set photo
     * @param image
     * @private
     */
    _setPhoto({image}) {
        this.photo = image;
    }

}
